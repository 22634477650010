/* eslint sort-keys: ["error", "asc", {allowLineSeparatedGroups: true, natural: true, minKeys: 5}] */
import { Color } from '@mui/material/index'
import { Components, createTheme, Theme, ThemeOptions } from '@mui/material/styles'
import type {} from '@mui/x-data-grid-premium/themeAugmentation'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'

import { withFocusOutlineStyleOverrides } from '../utils/createFocusOutlineStyleOverrides'
import { isJSDOM } from '../utils/test/isJSDOM'
import { withDisabledTransitions } from '../utils/test/withDisabledTransitions'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    selected: Palette['primary']
    orange: Color
    yellow: Color
    purple: Color
  }
  interface PaletteOptions {
    selected: PaletteOptions['primary']
    orange?: Partial<Color>
    yellow?: Partial<Color>
    purple?: Partial<Color>
  }
}

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    fontWeightSemiBold?: number
  }

  interface Typography {
    fontWeightSemiBold: number
  }
}

const themeOptions: ThemeOptions = withDisabledTransitions({
  palette: {
    common: {
      black: '#202020',
      white: '#fefefe',
    },
    error: {
      main: '#df3940',
    },
    grey: {
      50: '#F2F2F2',
      100: '#edf1f5',
      200: '#D8D8D8',
      300: '#edf1f5',
      400: '#c5ced6',
      500: '#97a3ae',
      600: '#636363',
      700: '#303840',
      800: '#242a30',
      900: '#0a0b0d',
    },
    info: {
      main: '#3db1c8',
      dark: '#236d7c',
    },
    orange: {
      300: '#FA924E',
      500: '#F26318',
      600: '#C54E01',
    },
    primary: {
      light: '#c1e1ea',
      main: '#3db1c8',
      dark: '#1d5c68',
      contrastText: '#ffffff',
    },
    purple: {
      400: '#9F4FD0',
    },
    secondary: {
      main: '#50bc7f',
      contrastText: '#ffffff',
    },
    selected: {
      main: '#3db1c8',
      light: '#c1e1ea',
    },
    success: {
      light: '#78be43',
      main: '#50bc7f',
      dark: '#006830',
    },
    warning: {
      main: '#e3aa00',
      dark: '#f96302',
    },
    yellow: {
      500: '#FDC70C',
    },
  },
  typography: {
    body1: { fontSize: 14 },
    body2: { fontSize: 12 },
    button: {
      fontFamily: 'Lato',
      textTransform: 'none',
      fontSize: 14,
    },
    caption: { fontSize: 12 },
    fontFamily: 'Lato',
    fontWeightSemiBold: 600,
    h2: {
      fontSize: 28,
      fontWeight: 500,
    },
    h6: { fontSize: 16 },
    subtitle2: {
      fontSize: 12,
    },
  },
})

const componentOverrides: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        backgroundColor: 'common.white',
        color: 'common.black',
      },
    },
  },

  // Core component overrides
  MuiAlert: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },

      filled: {
        color: 'white',
      },
      icon: {
        fontSize: 'inherit',
        padding: '10px 0',
      },

      standardError: {
        backgroundColor: '#FFE9E7',
      },
      standardInfo: {
        backgroundColor: '#E9EDF9',
      },
      standardSuccess: {
        backgroundColor: '#E5F5EF',
      },
      standardWarning: {
        backgroundColor: '#FEEFE6',
      },

      outlinedError: {
        color: '#CA1A0E',
        border: '1px solid #CA1A0E',
      },
      outlinedInfo: {
        color: '#2346C4',
        border: '1px solid #2346C4',
      },
      outlinedSuccess: {
        color: '#006830',
        border: '1px solid #006830',
      },
      outlinedWarning: {
        color: '#C54E01',
        border: '1px solid #C54E01',
      },

      filledError: {
        backgroundColor: '#CA1A0E',
      },
      filledInfo: {
        backgroundColor: '#2346C4',
      },
      filledSuccess: {
        backgroundColor: '#006830',
      },
      filledWarning: {
        backgroundColor: '#C54E01',
      },
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        fontSize: 18,
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        fontSize: 14,
        width: 300,
        '& .MuiAutocomplete-clearIndicator': {
          right: '0px',
        },
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      standard: ({ theme }) => ({
        // FIXME: use consistent border radius throughout the theme
        borderRadius: '12px',
        fontSize: 14,
        fontWeight: 500,
        height: theme.spacing(3),
        width: theme.spacing(3),
      }),
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      disableElevation: true,
    },
    styleOverrides: withFocusOutlineStyleOverrides({
      sizeLarge: {
        fontSize: 16,
      },
      sizeSmall: {
        fontSize: 12,
      },
    }),
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      disableElevation: true,
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        fontSize: 14,
        // FIXME: use consistent border radius throughout the theme
        borderRadius: '12px',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        marginBottom: 0,
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2, 3),
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        margin: theme.spacing(0, 0, 2),
        padding: theme.spacing(2, 2),
      }),
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        backgroundColor: '#fff',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: 10,
      },
      sizeSmall: {
        fontSize: 10,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
    },
  },
  MuiImageListItemBar: {
    styleOverrides: {
      title: {
        fontSize: 14,
      },
      subtitle: {
        fontSize: 12,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        fontSize: 14,
        backgroundColor: '#fff',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        fontFamily: 'Lato',
        textDecoration: 'none',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: 14,
        paddingLeft: theme.spacing(1),
      }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
    },
  },
  MuiStepContent: {
    styleOverrides: {
      root: {
        wordBreak: 'break-word',
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      iconContainer: ({ theme }) => ({
        marginLeft: theme.spacing(-2),
      }),
      labelContainer: {
        fontSize: 12,
        fontWeight: 500,
        wordBreak: 'break-word',
        overflow: 'hidden',
      },
      label: {
        fontSize: 16,
        fontWeight: 500,
        '&.Mui-active': {
          fontWeight: 700,
        },
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        fontSize: 20,
      },
      fontSizeSmall: {
        fontSize: 16,
      },
      fontSizeMedium: {
        fontSize: 20,
      },
      fontSizeLarge: {
        fontSize: 24,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: theme.spacing(4),
        paddingTop: 0,
        paddingBottom: 0,
      }),
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      displayedRows: {
        margin: 0,
        fontSize: 14,
      },
      selectLabel: {
        margin: 0,
        fontSize: 14,
      },
      select: {
        border: '1px solid #bdbdbd',
        // FIXME: use consistent border radius throughout the theme
        borderRadius: '8px',
        paddingBottom: 0,
        paddingTop: '5px',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: '36px',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.grey[200]}`,
        color: theme.palette.grey[700],
        fontSize: 12,
        padding: theme.spacing(1, 2),
      }),
      arrow: ({ theme }) => ({
        '&::before': {
          border: `2px solid ${theme.palette.grey[200]}`,
        },
        color: theme.palette.common.white,
      }),
    },
  },

  // Lab component overrides
  MuiDayCalendar: {
    styleOverrides: {
      weekDayLabel: {
        fontSize: '1.5rem',
      },
    },
  },

  // MUI X component overrides
  MuiDataGrid: {
    defaultProps: {
      // We disable virtualization in Jest to avoid grid data testing issues with JSDOM
      disableVirtualization: isJSDOM(),
    },
  },
  MuiDayPicker: {
    styleOverrides: {
      weekDayLabel: {
        fontSize: '1.5rem',
      },
    },
  },
  MuiPickersArrowSwitcher: {
    styleOverrides: {
      root: {
        span: { fontSize: '1.5rem' },
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        fontSize: '1.5rem',
      },
    },
  },
}

themeOptions.components = componentOverrides

export const vantageTheme = createTheme(themeOptions)
