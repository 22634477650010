/**
 * Vantage API v1 base URL
 *
 * We check for `window` object to avoid borking anything running in a Node environment.
 *
 * TODO: In future, we want to handle this via environment variable.
 */
export const API_V1_BASE_URL = typeof window !== 'undefined' ? `${window.location.origin}/api` : ''

/**
 * Vantage API v2 base URL
 *
 * We check for `window` object to avoid borking anything running in a Node environment.
 *
 * TODO: In future, we want to handle this via environment variable.
 */
export const API_V2_BASE_URL = typeof window !== 'undefined' ? `${window.location.origin}/api/v2` : ''
