import { createApi } from '@reduxjs/toolkit/query/react'

import { emptyUser, setUser, setUserCoBrand } from '../store/userSlice'
import { ChangePasswordPayload } from '../types/ChangePasswordPayload'
import { PersonalDetailsPayload } from '../types/PersonalDetailsPayload'
import { SystemMessage } from '../types/SystemMessage'
import { VantageUser } from '../types/VantageUser'

import { v2RootQuery } from './v2BaseQuery'

export const coreApi = createApi({
  reducerPath: 'core',
  baseQuery: v2RootQuery,
  tagTypes: ['Messages', 'Me', 'Users'],
  endpoints: (builder) => ({
    getMessages: builder.query<SystemMessage[], number | null>({
      query: (store_id) => ({
        url: '/messages/'.concat(store_id !== null ? store_id.toString().concat('/') : ''),
        method: 'GET',
      }),
    }),
    getUser: builder.query<VantageUser, unknown>({
      query: () => ({
        url: '/users/me/',
        method: 'GET',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }): Promise<void> {
        try {
          const { data } = await queryFulfilled
          dispatch(setUser(data))
          dispatch(setUserCoBrand(data.coBrand))
        } catch (err) {
          dispatch(setUser(emptyUser))
        }
      },
      providesTags: ['Me'],
    }),
    getUsers: builder.query<VantageUser[], { query: string }>({
      query: ({ query }) => {
        let url = '/users/'
        if (query) {
          url = `/users/?query=${query}`
        }
        return {
          url,
          method: 'GET',
        }
      },
      providesTags: ['Users'],
    }),
    setActiveRole: builder.mutation<VantageUser, string>({
      query: (role) => ({
        url: '/users/set-active-role/',
        method: 'POST',
        body: JSON.stringify({ role }),
      }),
      invalidatesTags: ['Me', 'Messages'],
    }),
    acceptTerms: builder.mutation<string, unknown>({
      query: () => ({
        url: '/users/accept-terms/',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Me'],
    }),
    updatePersonalDetails: builder.mutation<PersonalDetailsPayload, unknown>({
      query: (personalDetails) => ({
        url: `/users/`,
        method: 'PATCH',
        body: JSON.stringify(personalDetails),
      }),
    }),
    updatePassword: builder.mutation<ChangePasswordPayload, unknown>({
      query: (passwordData) => ({
        url: `/users/password`,
        method: 'PUT',
        body: JSON.stringify(passwordData),
      }),
    }),
  }),
})

export const {
  useGetMessagesQuery,
  useGetUserQuery,
  useGetUsersQuery,
  useUpdatePersonalDetailsMutation,
  useUpdatePasswordMutation,
  useSetActiveRoleMutation,
  useAcceptTermsMutation,
} = coreApi
