import { ThemeOptions, TransitionsOptions } from '@mui/material/styles'

/** Transitions with all durations set to 0. Helps expedite tests */
const disableTransitions: TransitionsOptions = {
  duration: {
    short: 0,
    shorter: 0,
    shortest: 0,

    complex: 0,
    standard: 0,

    enteringScreen: 0,
    leavingScreen: 0,
  },
}

/** HOF that disables MUI theme transitions in a test environment */
export const withDisabledTransitions = (themeOptions: ThemeOptions): ThemeOptions => {
  return {
    ...themeOptions,
    transitions: process.env['NODE_ENV'] === 'test' ? disableTransitions : themeOptions.transitions,
  }
}
