import { createApi } from '@reduxjs/toolkit/query/react'

import { csrfBaseQuery } from '@login/redux/apis/baseQuery'

import {
  AuthResponse,
  ForgotPasswordAttributes,
  ForgotPasswordResetAttributes,
  SignUpAttributes,
} from '../../models/auth.model'

export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: csrfBaseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, unknown>({
      query: (data) => ({
        url: `/auth/login/` + window.location.search,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      extraOptions: { maxRetries: 0 },
    }),
    signUp: builder.mutation<SignUpAttributes, unknown>({
      query: (data) => ({
        url: `/auth/signup/`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      extraOptions: { maxRetries: 0 },
    }),
    forgotPassword: builder.mutation<ForgotPasswordAttributes, unknown>({
      query: (data) => ({
        url: `/auth/forgot-password/`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      extraOptions: { maxRetries: 0 },
    }),
    forgotPasswordKey: builder.mutation<unknown, unknown>({
      query: (resetKey) => ({
        url: `/auth/forgot-password-key/` + resetKey,
        method: 'GET',
      }),
      extraOptions: { maxRetries: 0 },
    }),
    forgotPasswordReset: builder.mutation<ForgotPasswordResetAttributes, { resetKey?: string; data: object }>({
      query: (params) => {
        const { resetKey, data } = params
        return {
          url: `/auth/forgot-password-key/` + resetKey,
          method: 'POST',
          body: JSON.stringify(data),
        }
      },
      extraOptions: { maxRetries: 0 },
    }),
  }),
})

export const {
  useLoginMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useForgotPasswordKeyMutation,
  useForgotPasswordResetMutation,
} = authApi
