import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import { analytics } from '@lib/ui-config'

import { CoBrands } from '../types/CoBrand'
import { UserState } from '../types/UserState'
import { VantageUser } from '../types/VantageUser'

export const emptyUser: VantageUser = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  frontUserHash: '',
  coBrand: '',
  isBrandUser: false,
  isRetailerUser: false,
  roles: [],
  activeRole: null,
  phone: '',
  orgCount: 0,
}

const initialState: UserState = {
  user: emptyUser,
  coBrand: '',
}

export const userSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<VantageUser>) {
      Sentry.setUser({
        id: action.payload.id.toString(),
        email: action.payload.email,
        username: action.payload.firstName + ' ' + action.payload.lastName,
      })
      Sentry.setTag('username', action.payload.email)
      analytics.identify(action.payload.id.toString(), {
        email: action.payload.email,
        name: action.payload.firstName + ' ' + action.payload.lastName,
      })
      state.user = action.payload
    },
    setUserCoBrand(state, action: PayloadAction<CoBrands>) {
      state.coBrand = action.payload
    },
  },
})

export const { setUser, setUserCoBrand } = userSlice.actions
